import * as React from "react";
import { Helmet } from "react-helmet"

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  textAlign: "center",
};

const imgStyles = {
  maxWidth: "100%",
  height: "auto",
};

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet>
        <title>Martin and Bex</title>
      </Helmet>
      <img
        src="/static/martin-bex.jpg"
        width="478"
        height="478"
        style={imgStyles}
      />
    </main>
  );
};

export default IndexPage;
